import React, { useEffect, useMemo, useState } from 'react';
import styles from './Campaign.module.scss';
import AuthLayout from '../../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../../Components/SettingsHeader/SettingsHeader';
import { Button, Col, Image, Row, Select, Tooltip, Modal, Space, notification } from 'antd';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';
import cc from 'classnames';
import '../override.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  useParams,
  unstable_useBlocker as useBlocker,
  unstable_usePrompt as usePrompt,
} from 'react-router-dom';
import { getCampaign } from '../../../Redux/Features/getCampaignSlice';
import countryList from 'react-select-country-list';
import { formatDateToStandard, getAllProductsToDisplay, removeDuplicates } from '../../../utils/helper';
import { getEngagements } from '../../../Redux/Features/getEngagementsSlice';
import { updateCampaign } from '../../../Redux/Features/updateCampaignSlice';
import validator from 'validator';
import { pauseCampaign } from '../../../Redux/Features/pauseCampaignSlice';
import { resumeCampaign } from '../../../Redux/Features/resumeCampaignSlice';
import { deleteCampaign } from '../../../Redux/Features/deleteCampaignSlice';
import InputText from '../../../Components/Commons/InputText/InputText';
import { EditOutlined } from '@ant-design/icons';
import { getAccounts, resetAccountDetails } from '../../../Redux/Features/getAccountsSlice';
import { getCompanies, resetCompanyDetails } from '../../../Redux/Features/getCompaniesSlice';
import { repayCampaign } from '../../../Redux/Features/repayCampaignSlice';
import FilterElement from '../../../Components/Campaigns/FilterElement/FilterElement';
import TargetElement from '../../../Components/Campaigns/TargetElement/TargetElement';
import { updateSubscription } from '../../../Redux/Features/updateSubscriptionSlice';
import { createCheckoutSession } from '../../../Redux/Features/createCheckoutSessionSlice';
import { updateCampaignInternalSubscription } from '../../../Redux/Features/updateCampaignInternalSubscriptionSlice';
import parseCsv from '../../../Components/Campaigns/TargetElement/parseCsv';
import SettingsElement from '../../../Components/Campaigns/SettingsElement/SettingsElement';
import ScrollUpButton from '../../../Components/Campaigns/ScrollUpButton/ScrollUpButton';
import ScrollDownButton from '../../../Components/Campaigns/ScrollDownButton/ScrollDownButton';
const abbreviate = require('number-abbreviate');

const Campaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { profiles } = useSelector((state) => state?.getLinkedinProfiles);
  const { campaign, isSuccess, isLoading } = useSelector((state) => state?.getCampaign);
  const { engagements } = useSelector((state) => state?.getEngagements);
  const { products } = useSelector((state) => state?.getProducts);

  const { details: accountsDetails, isLoading: getAccountsLoading } = useSelector((state) => state?.getAccounts);
  const { details: companiesDetails, isLoading: getCompaniesLoading } = useSelector((state) => state?.getCompanies);

  const { isLoading: updateCampaignLoading, isSuccess: updateCampaignSuccess } = useSelector(
    (state) => state?.updateCampaign
  );

  const { isLoading: pausingCampaignLoading, isSuccess: pauseCampaignSuccess } = useSelector(
    (state) => state?.pauseCampaign
  );
  const { isLoading: resumingCampaignLoading, isSuccess: resumeCampaignSuccess } = useSelector(
    (state) => state?.resumeCampaign
  );
  const { isLoading: repayCampaignLoading } = useSelector((state) => state?.repayCampaign);

  const { isLoading: updateSubscriptionLoading } = useSelector((state) => state?.updateSubscription);

  const { isLoading: deletingCampaignLoading } = useSelector((state) => state?.deleteCampaign);
  const { isLoading: updateCampaignInternalSubscriptionLoading, isSuccess: updateCampaignInternalSubscriptionSuccess } =
    useSelector((state) => state?.updateCampaignInternalSubscription);

  const [formData, setFormData] = useState({});
  const [clientError, setClientError] = useState({});
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [isDeleteCampaign, setIsDeleteCampaign] = useState(false);
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [updateInternalSubscription, setUpdateInternalSubscription] = useState({
    show: false,
    product_id: '',
  });
  const [showTargets, setShowTargets] = useState({
    show: false,
    data: [],
  });

  const setField = (fieldName, fieldValue) => {
    setUnsavedChanges(true);
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const [hashtagsCsvProgress, setHashtagsCsvProgress] = useState(0);
  const [accountsCsvProgress, setAccountsCsvProgress] = useState(0);
  const [pagesCsvProgress, setPagesCsvProgress] = useState(0);
  const [locationsCsvProgress, setLocationsCsvProgress] = useState(0);
  const [headlineKeywordsCsvProgress, setHeadlineKeywordsCsvProgress] = useState(0);
  const [profilesFilterCsvProgress, setProfilesFilterCsvProgress] = useState(0);

  useBlocker(unsavedChanges); // Blocking the user to navigate the route when there is unsave changes
  usePrompt({
    // Prompting the user when there is unsave changes and blocked
    message: 'You have unsaved changes. Are you sure you want to leave?',
    when: unsavedChanges,
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Here we are controlling page reload and prompting when there is unsave changes
      if (unsavedChanges) {
        event.returnValue = true;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    dispatch(getCampaign({
      id,
      onError: (errorStatus) => {
        if (errorStatus == 401) {
          navigate('/campaigns')
        }
      }
    }));
  }, [id]);

  useEffect(() => {
    if (!isSuccess || !id) return;
    dispatch(getEngagements({ id: id, filter: 'days' }));
  }, [id, isSuccess]);

  useEffect(() => {
    setFormData(campaign);
  }, [campaign]);

  useEffect(() => {
    dispatch(resetAccountDetails());
    dispatch(resetCompanyDetails());
  }, []);

  const setProfileFilter = (data) => {
    // profiles filter is exclude-only, so we make sure we explicitly exclude
    data.is_disabled = false;
    data.include = false;
    setField('profilesFilter', data)
  }

  const getProductName = (product_id) => {
    const product = products?.find((item) => item?._id === product_id);
    return product?.product_name;
  };

  const doesProductAllowCommentingOnPost = (product_id) => {
    const product = products?.find((item) => item?._id === product_id);
    return product?.features?.commentOnPosts;
  };

  const getMaxRepliesPerPost = (product_id) => {
    const product = products?.find((item) => item?._id === product_id);
    const maxRepliesPerPost = product?.features?.maxRepliesPerPost;
    return !isNaN(parseInt(maxRepliesPerPost)) ? parseInt(maxRepliesPerPost) : null;
  }

  const linkedinAllAccountsHandler = () => {
    const copyProfiles = [...profiles];
    const newProfilesOptionsArr = [];
    copyProfiles?.filter((item, i) => newProfilesOptionsArr?.push({ value: item?._id, label: item?.linkedin_name }));
    return newProfilesOptionsArr[0] ? newProfilesOptionsArr : [];
  };

  const businessPagesHandler = (val) => {
    let copyProfiles = [...profiles];
    const newProfilesOptionsArr = [];
    copyProfiles = copyProfiles?.find((item, i) => item?._id === formData?.linkedin_account);
    copyProfiles?.business_pages?.filter((item, i) =>
      newProfilesOptionsArr?.push({ label: item?.name, value: item?.value })
    );
    if (val) {
      copyProfiles = copyProfiles?.business_pages?.find((item, i) => item?.value === val);
      return copyProfiles !== undefined ? { ...copyProfiles, value: copyProfiles?.value } : {};
    }
    return newProfilesOptionsArr[0] ? newProfilesOptionsArr : [];
  };

  const handleCountries = () => {
    const copyCountryOptions = [...countryOptions];
    let newCountryList = [];
    copyCountryOptions?.filter((item, i) => newCountryList?.push({ value: item?.label, label: item?.label }));
    return newCountryList[0] ? newCountryList : [];
  };

  const handleUpdateCampaign = () => {
    let isErr = false;
    let errors = {};
    const { is_active, status, subscription, product, worker_details, ...rest } = formData;

    if (validator.isEmpty(formData?.title)) {
      isErr = true;
      errors.title = 'Title is required';
    }
    if (validator.isEmpty(formData?.linkedin_account)) {
      isErr = true;
      errors.linkedin_account = 'Linkedin account is required';
    }
    if (validator.isEmpty(formData?.business_page?.value?.toString())) {
      isErr = true;
      errors.business_page = 'Business page is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      setUnsavedChanges(false);
      let data = {
        id: id,
        data: {
          ...rest,
          accounts: { ...rest?.accounts, items: removeDuplicates(rest?.accounts?.items, 'id') },
          pages: { ...rest?.pages, items: removeDuplicates(rest?.pages?.items, 'id') },
          hashtags: { ...rest?.hashtags, items: removeDuplicates(rest?.hashtags?.items) },
          profilesFilter: { ...rest?.profilesFilter, items: removeDuplicates(rest?.profilesFilter?.items, 'id') },
        },
      };

      dispatch(updateCampaign(data));
    }
  };

  useEffect(() => {
    let targetifTrue =
      pauseCampaignSuccess ||
      resumeCampaignSuccess ||
      updateCampaignSuccess ||
      updateCampaignInternalSubscriptionSuccess;
    if (targetifTrue) {
      dispatch(getCampaign({ id }));
    }
  }, [pauseCampaignSuccess, resumeCampaignSuccess, updateCampaignSuccess, updateCampaignInternalSubscriptionSuccess]);

  const handleLeastTargets = (length = 10) => {
    if (!campaign?.sortedTargets || campaign?.sortedTargets?.length === 0) return [];

    if (campaign?.sortedTargets?.length > 1) {
      const limit = Math.min(length, Math.floor(campaign?.sortedTargets?.length / 2));
      return campaign?.sortedTargets?.slice(campaign?.sortedTargets?.length - limit)?.filter(target => !!target._id)?.map((target) => target._id);
    } else {
      return [];
    }
  };

  const handleTopTargets = (length = 10) => {
    if (!campaign?.sortedTargets || campaign?.sortedTargets?.length === 0) return [];

    if (campaign?.sortedTargets?.length > 1) {
      const limit = Math.min(length, Math.floor(campaign?.sortedTargets?.length / 2));
      return campaign?.sortedTargets?.slice(0, limit)?.filter(target => !!target._id)?.map((target) => target._id);
    } else {
      return [campaign?.sortedTargets[0]._id];
    }
  };

  const handlePauseCampaign = () => {
    dispatch(pauseCampaign(id));
  };

  const handleResumeCampaign = () => {
    dispatch(resumeCampaign(id));
  };

  const handleDeleteCampaign = () => {
    let data = {
      id,
      onSuccess: () => {
        navigate('/campaigns');
      },
    };
    dispatch(deleteCampaign(data));
  };

  const handleAccountsDetails = (value) => {
    if (getAccountsLoading) return;
    if (!formData?.linkedin_account) {
      notification.error({ message: 'Error', description: 'Linkedin account not selected' });
    } else {
      dispatch(getAccounts({ id: formData?.linkedin_account, data: value }));
    }
  };

  const handlePagesDetails = (value) => {
    if (getCompaniesLoading) return;

    if (!formData?.linkedin_account) {
      notification.error({ message: 'Error', description: 'Linkedin account not selected' });
    } else {
      dispatch(getCompanies({ id: formData?.linkedin_account, data: value }));
    }
  };

  const handleEngagementDataByFilter = (type) => {
    if (!type) return;
    dispatch(getEngagements({ id: id, filter: type }));
  };

  const handleRepayCampaign = () => {
    dispatch(
      repayCampaign({
        id,
        data: {
          type: 'normal',
          returnPath: '/campaigns?success=true',
        },
        onSuccess: (sessionUrl) => (window.location = sessionUrl),
      })
    );
  };

  const handleUpdateInternalSubscription = () => {
    let isErr = false;
    let errors = {};

    if (updateInternalSubscription?.product_id && validator?.isEmpty(updateInternalSubscription?.product_id)) {
      isErr = true;
      errors.product_id = 'Subscription is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});

      let data = {
        id,
        data: {
          product_id: updateInternalSubscription?.product_id,
        },
      };
      dispatch(updateCampaignInternalSubscription(data));
    }
  };

  // used in case of draft
  const initiateCreateCheckoutSession = () => {
    let isErr = false;
    let errors = {};

    if (updateInternalSubscription?.product_id && validator?.isEmpty(updateInternalSubscription?.product_id)) {
      isErr = true;
      errors.product_id = 'Subscription is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});

      dispatch(
        createCheckoutSession({
          data: {
            campaign_id: id,
            product_id: updateInternalSubscription?.product_id,
          },
          onSuccess: (sessionUrl) => (window.location = sessionUrl),
        })
      );
    }
  };

  const handleParseCsvFile = (file, type) => {

    if (!formData?.linkedin_account && ["linkedin-profile", "linkedin-company", "profiles-filter"].includes(type)) {
      notification.error({ message: 'Error', description: 'Please select a linkedin account to parse csv file' });
      return false;
    }

    return parseCsv(file, type, formData?.linkedin_account, (newItem, progress) => {

      if (newItem) {

        if (type === 'linkedin-profile') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.accounts?.items || []), newItem];
            return {
              ...prevState,
              accounts: {
                ...prevState.accounts,
                items: newItems,
              }
            };
          });
          setAccountsCsvProgress(progress);

        } else if (type === 'linkedin-company') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.pages?.items || []), newItem];
            return {
              ...prevState,
              pages: {
                ...prevState.pages,
                items: newItems,
              }
            };
          });
          setPagesCsvProgress(progress);

        } else if (type === 'hashtag') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.hashtags?.items || []), newItem];
            return {
              ...prevState,
              hashtags: {
                ...prevState.hashtags,
                items: newItems,
              }
            };
          });
          setHashtagsCsvProgress(progress);

        } else if (type === 'locations') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.locations?.items || []), newItem];
            return {
              ...prevState,
              locations: {
                ...prevState.locations,
                items: newItems,
              }
            };
          });
          setLocationsCsvProgress(progress);

        } else if (type === 'headline-keywords') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.headlineKeywords?.items || []), newItem];
            return {
              ...prevState,
              headlineKeywords: {
                ...prevState.headlineKeywords,
                items: newItems,
              }
            };
          });
          setHeadlineKeywordsCsvProgress(progress);

        }
        else if (type === 'profiles-filter') {
          setFormData((prevState) => {
            const newItems = [...(prevState?.profilesFilter?.items || []), newItem];
            return {
              ...prevState,
              profilesFilter: {
                ...prevState.profilesFilter,
                items: newItems,
              }
            };
          });
          setProfilesFilterCsvProgress(progress);

        }
        else {
          notification.error({ message: 'Error', description: 'Invalid data type found while parsing csv. Not sure how to map it.' });
          return false;
        }


      }
    })
  }

  return (
    <AuthLayout loading={isLoading || pausingCampaignLoading || resumingCampaignLoading}>
      <SettingsHeader
        tabsSideContent={
          <div>
            <Button type="ghost" className="ant-btn-middle" onClick={() => setIsDeleteCampaign(true)}>
              Delete Campaign
            </Button>
            {campaign?.status !== 'Blocked' && campaign?.status !== 'Draft' && (
              <Button
                type="primary"
                className="ant-btn-middle"
                onClick={() => {
                  if (campaign?.created_by !== 'INTERNAL') {
                    dispatch(
                      updateSubscription({
                        id,
                        data: {
                          type: 'update',
                          returnPath: '/campaigns?success=true',
                        },
                        onSuccess: (sessionUrl) => (window.location = sessionUrl),
                      })
                    );
                  } else {
                    setUpdateInternalSubscription({
                      show: true,
                      product_id: formData?.product_id,
                    });
                  }
                }}
                disabled={campaign?.status === 'Blocked' || updateSubscriptionLoading}
                loading={updateSubscriptionLoading}
              >
                Update Subscription
              </Button>
            )}
            {campaign?.status !== 'Blocked' && campaign?.status !== 'Draft' && (
              <Button
                type="primary"
                danger
                className="ant-btn-middle"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  if (campaign?.created_by !== 'INTERNAL') {
                    dispatch(
                      updateSubscription({
                        id,
                        data: {
                          type: 'cancel',
                          returnPath: window.location.href,
                        },
                        onSuccess: (sessionUrl) => (window.location = sessionUrl),
                      })
                    );
                  }
                }}
                disabled={campaign?.status === 'Blocked' || updateSubscriptionLoading || campaign?.created_by == 'INTERNAL'}
                loading={updateSubscriptionLoading}
              >
                Cancel Subscription
              </Button>
            )}
            {// In case of Draft, show "choose subscription" button
              campaign?.status === 'Draft' && (
                <Button
                  type="primary"
                  danger
                  className="ant-btn-middle"
                  onClick={() => {
                    setUpdateInternalSubscription({
                      show: true,
                      product_id: formData?.product_id,
                    });
                  }}
                  disabled={campaign?.status !== 'Draft' || updateSubscriptionLoading}
                  loading={updateSubscriptionLoading}
                >
                  Choose Subscription
                </Button>
              )}
          </div>
        }
        content={
          < div className={styles.profile_setting_container} >
            <div className={styles.profile_details}>
              <h5>Campaigns</h5>
              {isTitleEditable ? (
                <div className={styles.input_container}>
                  <InputText
                    size={'medium'}
                    placeholder={'Enter campaign name'}
                    value={formData?.title}
                    onChange={(e) => setField('title', e?.target?.value)}
                    onPressEnter={() => formData?.title !== '' && setIsTitleEditable(false)}
                    error={clientError?.title ? clientError?.title : false}
                  />
                </div>
              ) : (
                <h2>
                  {formData?.title}
                  <EditOutlined onClick={() => setIsTitleEditable(true)} />
                </h2>
              )}
            </div>
            <div className={styles.details}>
              <div className={styles.linkedin_account}>
                <p>Linkedin accounts</p>
                <Select
                  className="select_container"
                  defaultValue="All Accounts"
                  options={linkedinAllAccountsHandler()}
                  onChange={(val) =>
                    setFormData({ ...formData, linkedin_account: val, business_page: { label: '', value: '' } })
                  }
                  value={formData?.linkedin_account ? formData?.linkedin_account : undefined}
                  suffixIcon={<Image src={'/Assets/arrow_select_icon.svg'} alt={'arrow icon'} preview={false} />}
                  status={clientError?.linkedin_account && 'error'}
                  style={{
                    width: 136.36,
                  }}
                />
              </div>
              <div className={styles.business_page}>
                <p>Business page</p>
                <Select
                  className="select_container"
                  defaultValue={'Select'}
                  options={businessPagesHandler()}
                  onChange={(val) => setField('business_page', businessPagesHandler(val))}
                  value={formData?.business_page?.value ? formData?.business_page?.value : undefined}
                  suffixIcon={<Image src={'/Assets/arrow_select_icon.svg'} alt={'arrow icon'} preview={false} />}
                  status={clientError?.business_page && 'error'}
                  style={{
                    width: 136.36,
                  }}
                />
              </div>
            </div>
            {
              !campaign?.is_active && campaign?.status === 'Paused' && (
                <div className={styles.paused_screen_container}>
                  <p>Campaign paused</p>
                </div>
              )
            }
          </div >
        }
      />
      < div className={cc(styles.main_campaign_details_container, 'main_campaign_details_container')} >
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={15}>
            <div className={styles.engagement_chart_container}>
              <div className={styles.engagement_header}>
                <h5>Engagement</h5>
                <div className={styles.dates_container}>
                  <p
                    className={engagements?.type === '12months' ? styles.active : ''}
                    onClick={() => handleEngagementDataByFilter('12months')}
                  >
                    12 Months
                  </p>
                  <p
                    className={engagements?.type === '6months' ? styles.active : ''}
                    onClick={() => handleEngagementDataByFilter('6months')}
                  >
                    6 Months
                  </p>
                  <p
                    className={engagements?.type === '30days' ? styles.active : ''}
                    onClick={() => handleEngagementDataByFilter('30days')}
                  >
                    30 Days
                  </p>
                  <p
                    className={engagements?.type === 'days' ? styles.active : ''}
                    onClick={() => handleEngagementDataByFilter('days')}
                  >
                    7 Days
                  </p>
                </div>
                <div className={styles.button_container}></div>
              </div>
              <div className={styles.engagement_chart}>
                {engagements?.data && engagements?.data?.length > 0 && (
                  <ResponsiveContainer width={'100%'} height={288}>
                    <AreaChart data={engagements?.data} margin={{ top: 30, right: 30, left: -10, bottom: 10 }}>
                      <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#EEF2FF" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#EEF2FF" stopOpacity={0.4} />
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="1 2" vertical={false} />

                      <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        fontSize={13}
                        fontFamily="'Plus Jakarta Sans', sans-serif"
                        color="#52525B"
                        fontWeight={500}
                        fontStyle={'normal'}
                        dy={20}
                      />
                      <YAxis
                        axisLine={false}
                        fontSize={12}
                        fontFamily="'Plus Jakarta Sans', sans-serif"
                        color="#52525B"
                        fontWeight={400}
                        fontStyle={'normal'}
                        tickLine={false}
                        allowDecimals={true}
                        tickFormatter={(value) => abbreviate(value, 0)}
                      />
                      <RechartsTooltip />
                      <Area
                        type="monotone"
                        dataKey="engagement"
                        stroke="#2563EB"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                        strokeWidth={2}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9}>
            <Row gutter={[0, 20]}>
              <Col xs={24} sm={24} md={24}>
                <div className={styles.top_targets_container}>
                  <div className={styles.header_details_container}>
                    <h6>Top Targets</h6>
                    {campaign?.sortedTargets?.length && (
                      <p onClick={() => setShowTargets({ show: true, data: campaign?.sortedTargets })}>
                        <span>See All Targets</span>
                        <img src={'/Assets/cheveron-right.svg'} alt={'Chevron right icon'} />
                      </p>
                    )}
                  </div>
                  <div className={styles.tags_container}>
                    {handleTopTargets()[0] ? (
                      handleTopTargets(10)?.map((item, i) => {
                        const isLongTag = item?.length > 20;
                        const tagElem = (
                          <div className={styles.tag} key={i}>
                            <p> {isLongTag ? `${item?.slice(0, 20)}...` : item}</p>
                          </div>
                        );
                        return isLongTag ? (
                          <Tooltip title={item} key={i}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })
                    ) : (
                      <p className={styles.tags_no_data}>No data found</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <div className={styles.least_engaged_targets}>
                  <div className={styles.header_details_container}>
                    <h6>Least Engaged Targets</h6>
                    {campaign?.sortedTargets?.length && (
                      <p onClick={() => setShowTargets({ show: true, data: campaign?.sortedTargets })}>
                        <span>See All Targets</span>
                        <img src={'/Assets/cheveron-right.svg'} alt={'Chevron right icon'} />
                      </p>
                    )}
                  </div>
                  <div className={styles.tags_container}>
                    {handleLeastTargets()[0] ? (
                      handleLeastTargets(10)?.map((item, i) => {
                        const isLongTag = item?.length > 20;
                        const tagElem = (
                          <div className={styles.tag} key={i}>
                            <p> {isLongTag ? `${item?.slice(0, 20)}...` : item}</p>
                          </div>
                        );
                        return isLongTag ? (
                          <Tooltip title={item} key={i}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })
                    ) : (
                      <p className={styles.tags_no_data}>No data found</p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.targets_container}>
          <h2>Targets</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12}>
              <TargetElement
                title={'Target Hashtags'}
                targetType={'hashtags'}
                description={''}
                tagClassName={'tag_purple'}
                inputClassName={'input_purple'}
                type={'input'}
                onSuccess={(data) => setField('hashtags', data)}
                data={formData?.hashtags}
                error={clientError?.hashtags && clientError?.hashtags}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'hashtag')}
                csvProgress={hashtagsCsvProgress}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <TargetElement
                title={'Target Profiles'}
                targetType={'profiles'}
                description={''}
                tagClassName={'tag_yellow'}
                inputClassName={'input_yellow'}
                type={'select'}
                onSuccess={(data) => {
                  setField('accounts', data);
                }}
                data={formData?.accounts}
                selectOptions={accountsDetails?.length > 0 ? accountsDetails : []}
                onSelectSearchEnter={(val) => handleAccountsDetails(val)}
                selectLoading={getAccountsLoading}
                error={clientError?.accounts && clientError?.accounts}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'linkedin-profile')}
                csvProgress={accountsCsvProgress}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <TargetElement
                title={'Target Companies'}
                targetType={'companies'}
                description={''}
                tagClassName={'tag_green'}
                inputClassName={'input_green'}
                type={'select'}
                onSuccess={(data) => {
                  setField('pages', data);
                }}
                data={formData?.pages}
                selectOptions={companiesDetails?.length > 0 ? companiesDetails : []}
                onSelectSearchEnter={(val) => handlePagesDetails(val)}
                selectLoading={getCompaniesLoading}
                error={clientError?.pages && clientError?.pages}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'linkedin-company')}
                csvProgress={pagesCsvProgress}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.filters_container}>
          <h2>Filters</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12}>
              <FilterElement
                title={'Location Filter'}
                description={''}
                tagClassName={'tag_blue'}
                inputClassName={'input_blue'}
                elementType={'select'}
                onSuccess={(data) => setField('locations', data)}
                data={formData?.locations}
                selectOptions={handleCountries()}
                error={clientError?.locations && clientError?.locations}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'locations')}
                csvProgress={locationsCsvProgress}
                showIncludeExcludeOptions={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FilterElement
                title={'Position Filter'}
                description={''}
                tagClassName={'tag_blue'}
                inputClassName={'input_blue'}
                elementType={'input'}
                onSuccess={(data) => setField('headlineKeywords', data)}
                data={formData?.headlineKeywords}
                error={clientError?.headlineKeywords && clientError?.headlineKeywords}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'headline-keywords')}
                csvProgress={headlineKeywordsCsvProgress}
                showIncludeExcludeOptions={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FilterElement
                title={'Profiles to Exclude'}
                description={''}
                tagClassName={'tag_blue'}
                inputClassName={'input_blue'}
                elementType={'select-with-search'}
                onSuccess={setProfileFilter}
                selectOptions={accountsDetails}
                onSelectSearchEnter={(val) => handleAccountsDetails(val)}
                selectLoading={getAccountsLoading}
                data={formData?.profilesFilter}
                error={clientError?.profilesFilter && clientError?.profilesFilter}
                onSelectClear={() => dispatch(resetAccountDetails())}
                onParseCsvFile={(file) => handleParseCsvFile(file, 'profiles-filter')}
                csvProgress={profilesFilterCsvProgress}
                showIncludeExcludeOptions={false}
              />
            </Col>
          </Row>
        </div>

        <div className={styles.filters_container}>
          <h2>Settings</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24}>
              <SettingsElement
                onSuccess={(data) => setField('settings', data)}
                data={formData?.settings}
                showCommentsOnPostsSetting={doesProductAllowCommentingOnPost(formData.product_id)}
                maxRepliesPerPostFromProductSettings={getMaxRepliesPerPost(formData.product_id)}
              ></SettingsElement>
            </Col>
          </Row>
        </div>

        <div className={styles.load_parameters_button_container}>
          <div className={styles.subscription_panel}>
            {campaign?.status !== 'Draft' && (
              <h4 className={styles.subscription_name}>
                Subscription:{' '}
                <span>
                  {getProductName(formData.product_id)}
                </span>
              </h4>)
            }
            {campaign?.status === 'Draft' && (
              <Button
                type="primary"
                danger
                className="ant-btn-middle"
                onClick={() => {
                  setUpdateInternalSubscription({
                    show: true,
                    product_id: formData?.product_id,
                  });
                }}
                disabled={campaign?.status !== 'Draft' || updateSubscriptionLoading}
                loading={updateSubscriptionLoading}
              >
                Choose Subscription
              </Button>
            )}
            {campaign?.status !== 'Draft' && (campaign?.created_by !== 'INTERNAL' ? (
              <>
                <br />
                <h4 className={styles.subscription_name}>
                  Next charge: <span>{formatDateToStandard(formData?.current_period_end * 1000)}</span>
                </h4>
              </>
            ) : (
              <>
                <br />
                <h4 className={styles.subscription_name}>
                  Internal Campaign (No Payment)
                </h4>
              </>
            )
            )}
          </div>
          <div className={styles.button_container}>
            {campaign?.status !== 'Blocked' ? (
              <>
                <Button
                  type="default"
                  className="ant-btn-middle"
                  onClick={campaign?.is_active ? handlePauseCampaign : handleResumeCampaign}
                  loading={pausingCampaignLoading || resumingCampaignLoading}
                  disabled={campaign?.status === 'Disabled' || campaign?.status === 'Blocked'}
                >
                  {campaign?.is_active ? 'Pause campaign' : 'Resume campaign'}
                </Button>
                <Button
                  type="primary"
                  className="ant-btn-middle"
                  onClick={handleUpdateCampaign}
                  loading={updateCampaignLoading}
                  disabled={campaign?.status === 'Blocked'}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                className="ant-btn-middle"
                onClick={handleRepayCampaign}
                loading={repayCampaignLoading}
                disabled={campaign?.status !== 'Blocked'}
              >
                Repay
              </Button>
            )}
          </div>
        </div>
        {
          campaign?.message && (
            <div className={styles.disabled_status_message}>
              <p>Message: {campaign?.message}</p>
            </div>
          )
        }
      </div >
      <ScrollDownButton />
      <ScrollUpButton />

      <Modal
        centered
        closeIcon={true}
        open={updateInternalSubscription?.show}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setUpdateInternalSubscription({ show: false, product_id: '', name: '' })}
      >
        <div className={styles.update_subscription_container}>
          <h6>Choose Subscription</h6>
          <div className={styles.subscription_selection}>
            <Select
              className="select_container select_container_full"
              defaultValue={'Choose Subscriptions'}
              size={'large'}
              options={getAllProductsToDisplay(products)}
              onChange={(val) => {
                setUpdateInternalSubscription({
                  ...updateInternalSubscription,
                  product_id: val,
                });
              }}
              suffixIcon={<Image src={'/Assets/arrow_select_icon.svg'} alt={'arrow icon'} preview={false} />}
              status={clientError?.product_id && 'error'}
              value={updateInternalSubscription?.product_id}
            />
            <small className={styles.space} />
            <span className={'error_message'}>{clientError?.product_id}</span>
          </div>
          <div className={styles.button_container}>
            <Button
              size={'small'}
              type="default"
              onClick={() => {
                if (campaign.status === 'Draft') {
                  initiateCreateCheckoutSession()
                } else {
                  handleUpdateInternalSubscription()
                }
              }}
              loading={updateCampaignInternalSubscriptionLoading}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        closeIcon={true}
        open={showTargets?.show}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setShowTargets({ show: false, data: [], type: '' })}
      >
        <div className={styles.modal_targets}>
          <h6>Targets</h6>
          <div className={styles.tags_container}>
            {showTargets?.data?.map((item, i) => (
              <div className={styles[showTargets?.type]} key={i}>
                <p>
                  {' '}
                  {item._id?.length > 20 ? `${item?._id.slice(0, 20)}...` : item._id} ({item.score})
                </p>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Modal
        centered
        closeIcon={true}
        open={isDeleteCampaign}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsDeleteCampaign(false)}
      >
        <div className={styles.delete_campaign}>
          <h6>Delete</h6>
          <p>Confirm to delete the campaign</p>
          <div className={styles.button_container}>
            <Button size={'small'} type="primary" onClick={handleDeleteCampaign} loading={deletingCampaignLoading}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </AuthLayout >
  );
};

export default Campaign;
