import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  engagements: [],
};

export const getEngagements = createAsyncThunk('Campaign/get-engagements', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/campaign/get-engagements/${data?.id}/${data?.filter}`);
    if (response?.data) {
      const newData = response?.data?.engagements?.data.map((item) => {
        if (isNaN(parseFloat(item?.engagement))) {
          item.engagement = 0;
        }
        return item;
      });
      response.data.engagements.data = newData;
    }
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const getEngagementsSlice = createSlice({
  name: 'Get Engagements',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEngagements.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getEngagements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.engagements = action?.payload?.engagements;
    });
    builder.addCase(getEngagements.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.engagements = [];
      state.isError = true;
    });
  },
});

export default getEngagementsSlice?.reducer;
