import React, { useState, useEffect } from 'react';
import styles from './SettingsElement.module.scss';
import cc from 'classnames';
import { Checkbox, Input, Row, Col, Select, Tooltip, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const SettingsElement = ({
  onSuccess,
  data,
  showCommentsOnPostsSetting,
  maxRepliesPerPostFromProductSettings
}) => {

  const [limitLikesPerPost, setLimitLikesPerPost] = useState(false);
  const [likesPerPost, SetLikesPerPost] = useState(0);
  const [commentOnPosts, setCommentOnPosts] = useState(false);
  const [authorReengagementPeriodDays, setAuthorReengagementPeriodDays] = useState('0');
  const [replyToCommentsOnPost, setReplyToCommentsOnPost] = useState(false);
  const [maxRepliesPerPost, setMaxRepliesPerPost] = useState(0);

  const getAllSettings = () => {
    return {
      limitLikesPerPost,
      likesPerPost,
      commentOnPosts,
      authorReengagementPeriodDays,
      replyToCommentsOnPost,
      maxRepliesPerPost
    };
  }

  const onChangeLimitLikesCheckbox = (e) => {
    setLimitLikesPerPost(e.target.checked);
    onSuccess({ ...getAllSettings(), limitLikesPerPost: e.target.checked });
  };

  const onChangeLimitLikesValue = (e) => {
    SetLikesPerPost(e.target.value);
    onSuccess({ ...getAllSettings(), likesPerPost: e.target.value });
  };

  const onChangeCommentOnPosts = (e) => {
    setCommentOnPosts(e.target.checked);
    onSuccess({ ...getAllSettings(), commentOnPosts: e.target.checked });
  };

  const onChangeReengagementPeriod = (value) => {
    setAuthorReengagementPeriodDays(value);
    onSuccess({ ...getAllSettings(), authorReengagementPeriodDays: value });
  };

  const onChangeReplyToCommentsOnPosts = (e) => {
    setReplyToCommentsOnPost(e.target.checked);
    onSuccess({ ...getAllSettings(), replyToCommentsOnPost: e.target.checked });
  };

  const onChangeMaxRepliesPerPost = (e) => {

    if (maxRepliesPerPostFromProductSettings && parseInt(e.target.value) > maxRepliesPerPostFromProductSettings) {
      e.target.value = maxRepliesPerPostFromProductSettings;
    }

    setMaxRepliesPerPost(e.target.value);
    onSuccess({ ...getAllSettings(), maxRepliesPerPost: e.target.value });
  };

  useEffect(() => {
    if (data) {
      setLimitLikesPerPost(data?.limitLikesPerPost);
      SetLikesPerPost(data?.likesPerPost);
      setCommentOnPosts(data?.commentOnPosts);
      setAuthorReengagementPeriodDays(data?.authorReengagementPeriodDays);
      setReplyToCommentsOnPost(data?.replyToCommentsOnPost);
      setMaxRepliesPerPost(data?.maxRepliesPerPost);
    }
  }, [data]);

  return (
    <div className={cc(styles.custom_tags_container)}>
      <div className={styles.header_details_container}>
        <div className={styles.title}>
          <h6>Campaign Settings</h6>
        </div>
      </div>
      <div className={styles.tags_container}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Row>
            <Col span={12}>
              <Checkbox className={styles.flex_equal} checked={limitLikesPerPost} onChange={onChangeLimitLikesCheckbox}>Limit Likes Per Post</Checkbox>
            </Col>
            <Col span={12}>
              <Input
                className={styles.flex_equal}
                size="small"
                value={likesPerPost}
                onChange={onChangeLimitLikesValue}
                disabled={!limitLikesPerPost}
              />
            </Col>
          </Row>
          {showCommentsOnPostsSetting &&
            <>
              <Row>
                <Col span={12}>
                  <Checkbox className={styles.flex_equal} checked={commentOnPosts} onChange={onChangeCommentOnPosts}>Comment on Posts</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  Re-engage with the same author &nbsp;
                  <Tooltip title='How often should the campaign interact (comment/reply) the posts from the same author'>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Select
                    className="select_container"
                    defaultValue="No Restriction"
                    options={[
                      { value: 0, label: 'No Restriction' },
                      { value: 3, label: 'every 3 days' },
                      { value: 7, label: 'every 1 week' },
                      { value: 30, label: 'every 1 month' },
                      { value: 90, label: 'quarterly' },
                      { value: 365, label: 'yearly' },
                      { value: -1, label: 'never' }
                    ]}
                    onChange={onChangeReengagementPeriod}
                    value={authorReengagementPeriodDays}
                    style={{ width: '300px' }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Checkbox className={styles.flex_equal} checked={replyToCommentsOnPost} onChange={onChangeReplyToCommentsOnPosts}>Reply to comments on posts</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  Max. Number of Replies Per Post &nbsp; {maxRepliesPerPostFromProductSettings ? `(Max.: ${maxRepliesPerPostFromProductSettings}) ` : ''}
                  <Tooltip title='Maximum number of replies the bot will leave on comments of a post. There might be an upper limit set by the admin.'>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Input
                    className={styles.flex_equal}
                    size="small"
                    value={maxRepliesPerPost}
                    onChange={onChangeMaxRepliesPerPost}
                    disabled={!replyToCommentsOnPost}
                  />
                </Col>
              </Row>

            </>
          }
        </Space>
      </div>

    </div>
  );
};

export default SettingsElement;
